<template>
  <div class="stack-order page">
    <transition name="fade" mode="out-in">
      <div v-if="!configurator.length" class="stack-order__loading">
        <base-loader />
      </div>
      <div v-else-if="list.length" class="stack-order__content">
        <page-block v-if="configurator.length" :title="$t('additional')" size="bigger">
          <div class="l-flex-gutter-20">
            <div class="l-col-12_ml-8">
              <main-card class="stack-order__card">
                <order-config
                  :key="current.id"
                  :configurator="current"
                  class="stack-order__config"
                  :period="period"
                  @init="updateConfig"
                  @change="updateConfig"
                  @destroy="resetConfig"
                />
                <transition name="fade" mode="out-in">
                  <page-block v-if="costDetails" :title="$t('summary')" size="bigger">
                    <order-summary :details="costDetails" />
                  </page-block>
                </transition>
                <template #footer>
                  <div class="stack-order__footer">
                    <div class="amount-promo">
                      <!--                        :hint="$t('promohint')"-->
                      <base-input
                        v-model="promoCode"
                        :hint="'Вы можете применить  промокод, он будет влиять на стоимость ежедневно выгружаемых расходов.<br /> Промокод применяется к отдельной услуге в корзине, стартовать услуга при этом будет через корзину. <br />Если Вы хотите использовать промокод и не оплачивать другие товары в корзине, <br />удалите все дополнительные заказы из корзины. Если промокода нет, оставьте поле пустым, <br />инфраструктуру можно будет активировать не зависимо от корзины.'"
                        size="small"
                        :label="$t('promo')"
                        use-reactive-validation
                        @change="onInput"
                      >
                      </base-input>
                    </div>
                  </div>
                  <div class="stack-order__footer">
                    <div class="stack-order__actions">
                      <!--                        :disabled="isCalculating || isSendingToBasket"-->
                      <base-button
                        :loading="isSendingToPay"
                        :disabled="isSendingToBasket"
                        class="stack-order__btn"
                        @click="promoCode ? sendPromo(promoCode) : sendToPay()"
                      >
                        <!--                        @click="sendToPay()"-->
                        {{ promoCode ? 'Применить промокод' : 'Активировать' }}
                      </base-button>
                    </div>
                  </div>
                </template>
              </main-card>
            </div>
          </div>
        </page-block>
      </div>
    </transition>
  </div>
</template>

<script>
import OrderConfig from '@/components/Configurator/OrderConfig';
import OrderSummary from '@/components/Configurator/OrderSummary';
import MainCard from '@/components/MainCard/MainCard';
import { debounce } from 'lodash';
import showErrorModal from '@/mixins/showErrorModal';
import wizard from '@/mixins/billmgr/wizard';
import handleRedirect from '@/mixins/billing/handleRedirect';
import updateBalance from '@/mixins/billing/updateBalance';
import { BillMgrPeriods } from '@/models/BillMgr/periods';

import PaymentMethodConfigurator from '@/models/BillMgr/PaymentMethodConfigurator';
import qs from 'qs';
import BaseInput from '@/components/BaseInput/BaseInput';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import Vue from 'vue';
import modals from '@/mixins/modals';
export default {
  name: 'StackOrderDetails',
  components: { OrderConfig, OrderSummary, BaseInput, MainCard /*, BaseSelect */ },
  mixins: [showErrorModal, wizard, handleRedirect, updateBalance, modals],
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      promoCode: '',
      enterPromo: false,
      sureModal: null,
      addPromo: null,
      configData: null,
      order: null,
      period: -50,
      cost: 0,
      costDetails: null,
      isCalculating: false,
      isSendingToBasket: false,
      isSendingToPay: false,
      startFunc: 'payment.add',
      startParams: {},
      configuratorClass: PaymentMethodConfigurator,
    };
  },
  computed: {
    list() {
      return this.$store.state.moduleStack.moduleStackOrder.list;
    },
    loading() {
      return this.$store.state.moduleStack.moduleStackOrder.loading;
    },
    current() {
      return this.$store.getters['moduleStack/moduleStackOrder/current'] || this.currentItem;
    },

    configurator() {
      if (!this.current) return [];
      return this.current.configurator || [];
    },
    configTextFields() {
      return this.configurator.filter(addon => addon.type === 'text');
    },
    configLabels() {
      return this.configurator.reduce((acc, item) => {
        acc[item.name] = item.label;
        return acc;
      }, {});
    },
    priceByPeriod() {
      return this.current.priceByPeriod.map(i => ({
        label: this.$tc(`period.${BillMgrPeriods[i.period]}_c`, i.period),
        value: i.period,
      }));
    },
    periodValue() {
      return this.priceByPeriod.find(i => i.value === this.period);
    },
    balance() {
      return this.$store.getters['moduleProfile/balance'];
    },
    priceListId() {
      return this.current.id;
    },
    currentItem() {
      return this.list.find(i => i.id === +this.id);
    },
    getModel() {
      return this.$store.getters['moduleBasket/getModel'];
    },
  },
  watch: {
    order(event) {
      // console.log(event);
      // this.period = +this.$attrs.period;
    },
  },
  mounted() {
    if (localStorage.getItem('promo')) this.order = localStorage.getItem('promo');
    if (!this.current) {
      // console.log('1');
      this.$store.dispatch('moduleStack/moduleStackOrder/setCurrent', +this.id);
    }

    // if (!!this.configurator && !this.list) {
    if (!!this.configurator) {
      // console.log('2');
      setTimeout(() => this.setCurrent(this.current), 1500);
    }
  },
  beforeRouteLeave(_to, _from, next) {
    this.$store.dispatch('moduleStack/moduleStackOrder/reset');

    next();
  },
  methods: {
    onInput(event) {
      this.$emit('promocode', event.target.value);
    },
    basket() {
      this.$root.$refs.HeaderBasket.openMore();
    },
    async sendPromo(promoCode) {
      this.$modals.open({
        name: 'addPromo',
        component: BaseLoader,
        closable: false,
        onOpen: inst => (this.addPromo = inst),
        onClose: () => (this.addPromo = null),
      });
      await this.sendToBasket();
      let text = '';
      let res = '';
      this.$store
        .dispatch('moduleBasket/sendPromo', promoCode)
        .then(() => this.$store.dispatch('moduleBasket/fetchBasket'))
        .then(() => this.$emit('check', this.getModel))
        .then(() => (this.enterPromo = true))
        .then(() => {
          this.$modals.close();
          setTimeout(() => {
            this.isSendingToPay = false;
            this.$router.push({ name: 'stackMain' });
          }, 100);
        })
        .then(() => this.basket())
        .catch(e => {
          this.$store.dispatch('moduleBasket/fetchBasket');
          res = 'fail';
          text = e;
          Vue.set(this.addPromo, 'text', text);
          Vue.set(this.addPromo, 'component', null);
          Vue.set(this.addPromo, 'closable', true);
          Vue.set(this.addPromo, 'footer', {
            centered: true,
            confirm: {
              props: { title: this.$t('ok') },
              on: {
                click: () => {
                  this.$modals.close();
                },
              },
            },
          });
          this.showError(e);
        })
        .finally(() => {
          if (res === 'fail') {
            this.$store
              .dispatch('moduleBasket/removeFromBasketErrorPromocode', this.order)
              .finally(() => {
                this.$store.dispatch('moduleBasket/fetchBasket');
              });
          }
        });
    },
    // api and logic methods
    fetchCalc(data) {
      return this.$store.dispatch('moduleStack/moduleStackOrder/fetchCalc', data);
    },
    setCurrent(item) {
      this.$store.dispatch('moduleStack/moduleStackOrder/setCurrent', +item.id);
      if (!!this.configurator)
        this.fetchParams(item).then(() => this.calcSum(this.current.info.model));
    },
    fetchParams(item, period) {
      const params = {};
      if (item) params.id = item.id;
      if (period) params.period = period;
      return this.$store.dispatch('moduleStack/moduleStackOrder/fetchParams', params);
    },
    calcSum: debounce(function (payload = this.configData) {
      const params = {
        pricelist: this.current.id,
        period: this.period,
      };
      if (payload) Object.assign(params, payload);
      params.period = this.period;
      if (!this.isCalculating) {
        this.isCalculating = true;
        this.fetchCalc(params)
          .then(data => {
            const { cost } = data.model;
            this.cost = parseFloat(cost);
            // this.costDetails = cost_details;
            // this.costDetails.other = this.configTextFields.map(addon => ({
            //   label: addon.label,
            //   value: this.configData[addon.name],
            // }));
          })
          .catch(e => this.showError(e))
          .finally(() => setTimeout(() => (this.isCalculating = false), 100));
      }
    }, 500),
    getFormDataParams(payload = {}) {
      const formData = this.configData ? this.configData : this.current.info.model;
      if (
        this.configData &&
        this.configData.autoprolong &&
        this.configData.autoprolong !== 'null'
      ) {
        formData.autoprolong = this.period;
      }
      const params = {
        pricelist: this.current.id,
        ...formData,
        ...payload,
        period: this.period,
      };
      return params;
    },
    async sendOrder(payload = {}) {
      // console.log(payload);
      const params = Object.keys(payload).length ? payload : this.getFormDataParams(payload);
      // console.log(params);
      return this.$store
        .dispatch('moduleStack/moduleStackOrder/sendOrder', params)
        .catch(e => this.showError(e));
    },
    async sendToBasket() {
      this.isSendingToBasket = true;
      await this.sendOrder()
        .then(data => {
          // console.log(data);
          this.order = data.itemdesc[0]['billorderitem.id'];
          localStorage.setItem('promo', this.order);
          // this.addToBasketGtm(data, 'OpenStack');
          const cost = parseFloat(data.itemdesc[0].total_cost);
          this.resetCurrent();
          const text = this.$t('success.basket', { num: this.$n(cost, 'currency') });
          this.$store.dispatch('moduleBasket/fetchBasket');
          this.$store.dispatch('moduleStack/fetchList');
          // this.$gtm.trackEvent({
          //   event: '_event_arrange', // Event type [default = 'interaction'] (Optional)
          //   category: 'Stack',
          //   action: 'click',
          //   label: 'basket',
          //   value: 5000,
          //   noninteraction: false, // Optional
          // });
          // this.showSuccessModal(text);
        })
        .finally(() => {
          this.$store.dispatch('moduleBasket/fetchBasket');
          setTimeout(() => (this.isSendingToBasket = false), 100);
        });
    },
    sendToPay() {
      // this.$gtm.trackEvent({
      //   event: '_event_payment', // Event type [default = 'interaction'] (Optional)
      //   category: 'Stack',
      //   action: 'click',
      //   label: 'pay',
      //   value: 5000,
      //   noninteraction: false, // Optional
      // });
      if (this.order) {
        const free = {
          clicked_button: 'free',
          func: 'basket',
          id: this.order,
          sok: 'ok',
          out: 'bjson',
          // ...this.startParams,
        };
        this.sendOrder(free).then(data => {
          if (data && data.ok && data.ok.type && data.ok.type === 'form' && data.ok.v) {
            // this.showSuccessModal(this.$t('success.free'));
            this.showResModal(this.$t('success.free'));
          }
        });
      } else {
        const payload = {
          not_for_order: 'on',
          skipbasket: '',
          newface: 'on',
          newbasket: 'on',
          clicked_button: 'basket',
          endoflife: 'off',
        };
        const params = this.getFormDataParams(payload);
        this.showResFunc.success = false;
        this.isSendingToPay = true;
        this.sendOrder(params)
          .then(data => {
            this.$store.dispatch('moduleBasket/fetchBasket');
            if (data && data.ok && data.ok.type && data.ok.type === 'form' && data.ok.v) {
              const { billorder } = qs.parse(data.ok.v);
              this.startParams = { billorder };
              if (this.cost === 0) {
                const free = {
                  clicked_button: 'free',
                  func: 'basket',
                  ...this.startParams,
                };
                this.sendOrder(free).then(data => {
                  if (data && data.ok && data.ok.type && data.ok.type === 'form' && data.ok.v) {
                    // this.showSuccessModal(this.$t('success.free'));
                    this.showResModal(this.$t('success.free'));
                    // this.$store.dispatch('moduleStack/fetchList');
                  }
                });
                // return this.showSuccessModal(this.$t('success.free'));
                return this.$store.dispatch('moduleStack/fetchList');
              } else {
                this.runWizardPay({ notification: true })
                  .then(async data => {
                    const res = await this.handleRedirect(data.ok);
                    this.resetCurrent();
                    if (res.ok) {
                      const text =
                        res.func === 'redirect'
                          ? this.$t('success.redirect')
                          : this.$t('success.pay', { num: this.$n(this.cost, 'currency') });
                      // this.showSuccessModal(text);
                      this.showResModal(text);
                    }
                    await this.$store.dispatch('moduleStack/fetchList');
                    this.updateBalance();
                    // this.$gtm.trackEvent({
                    //   event: '_event_withdrawal', // Event type [default = 'interaction'] (Optional)
                    //   category: 'Balance',
                    //   action: 'click',
                    //   label: 'Pay',
                    //   value: 4000,
                    //   noninteraction: false, // Optional
                    // });
                  })
                  .catch(() => {
                    const basketItem = this.$store.state.moduleBasket.shadow[billorder][0];
                    if (basketItem) {
                      this.$store
                        .dispatch('moduleBasket/removeFromBasket', basketItem)
                        .finally(() => {
                          this.$store.dispatch('moduleBasket/fetchBasket');
                        });
                    }
                  });
              }
            }
          })
          .finally(() =>
            setTimeout(() => {
              this.isSendingToPay = false;
              this.$router.push({ name: 'stackMain' });
            }, 100)
          );
      }
    },
    // data methods
    updateConfig(data) {
      this.configData = { ...data };
      this.calcSum();
    },
    resetConfig() {
      this.configData = null;
      this.costDetails = null;
    },
    resetCurrent() {
      this.resetConfig();
      this.period = -50;
      this.$store.commit('moduleStack/moduleStackOrder/setCurrent', null);
    },
    onPeriodChange(obj) {
      this.period = obj.value;
      this.calcSum();
      this.$emit('change-period', this.period);
    },
    // showSuccessModal(text) {
    //   this.$modals.open({
    //     name: 'SuccessOrder',
    //     size: 'small',
    //     text,
    //   });
    // },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Создание Публичного облака",
    "choise": "Выберите тариф",
    "additional": "Дополнительно",
    "sendPromo" : {
      "success" : "Промокод успешно применен"
    },
    "promo": "Промокод",
    "promohint": "Вы можете применить  промокод, он будет влиять на стоимость ежедневно выгружаемых расходов. Промокод применяется к отдельной услуге в корзине, стартовать услуга при этом будет через корзину. Если Вы хотите использовать промокод и не оплачивать другие товары в корзине, удалите все дополнительные заказы из корзины. Если промокода нет, оставьте поле пустым, инфраструктуру можно будет активировать не зависимо от корзины.",
    "ok": "Ок",
    "confirm": "Применить",
    "summary": "Итого",
    "payfor": "при оплате за",
    "pay": "Оплатить",
    "hint": "Опция позволяет автоматически стартовать инфраструктуру и созданные сервера внутри, на балансе или привязанном способе оплаты должны быть средства, которых хваит для поднятия сервиса",
    "order": "В корзину",
    "specs": {
      "disc": "{n} ГБ SSD",
      "mem": "{n} ГБ RAM",
      "ncpu": "{n} CPU"
    },
    "success": {
      "basket": "Заказ на {num} успешно добавлен в корзину",
      "pay": "Услуга 'Публичное облако' успешно создано, {num} списано с лицевого счета",
      "free": "Запрос на подключение услуги публичного облака успешно создан. Дождитесь обновления данных.",
      "redirect": "Запрос на подключение услуги публичного облака успешно создан, продолжите оплату и дождитесь обновления данных"
    },
    "needbalance": "Запрос на подключение услуги публичного облака успешно создан, продолжите оплату и дождитесь обновления данных"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.stack-order {
  &__loading {
    flexy(center, center);
    flex: 1 1 100%;
  }
  &__sum {
    flex: 0 0 100%;

    +breakpoint(ms-and-up) {
      flex: 0 0 auto;
      margin-right: 0.5rem;
    }
  }
  &__price {
    flexy(flex-start, center, wrap);

    &-text {
      margin-right: 0.5rem;

      +breakpoint(sm-and-up) {
        font-size: $font-size-bigger;
      }
    }
  }
  &__period {
    flex: 0 0 8.3rem;

    +breakpoint(sm-and-up) {
      flex: 0 0 10rem;
    }
  }

  &__actions {
    margin: 0.75rem -0.75rem -0.75rem;
    flexy(flex-start, center, wrap);
  }

  &__btn {
    margin: 0.75rem;

    +breakpoint(xs-only) {
      flex: 1 1 100%;
    }
  }
}
</style>
